
import React, { useState } from 'react';
import Modal from '../components/walletcomponents/modal';

interface Image {
  title: string;
  src: string;
}

const imageArray: Image[] = [
  {
    title: "Trust",
    src: require('../assets/trust.png')
  },
  {
    title: "Metamask",
    src: require('../assets/meta.png')
  },
  {
    title:"Wallet Connect",
    src:require('../assets/wallet.jpg')
  },
  {
    title:"Phantom",
    src:require('../assets/phantom.webp')
  },
  {
    title:"Atomic",
    src:require('../assets/atomic.png')
  },
  {
    title:"Rainbow",
    src:require('../assets/rainbow.png')
  }, {
    title:"Argent",
    src:require('../assets/argent.jpg')
  }, {
    title:"Gnosis",
    src:require('../assets/gnosis.jpg')
  },
  {
    title:"Crypto.com",
    src:require('../assets/crypto.jpg')
  }, {
    title:"Pillar",
    src:require('../assets/pillar.png')
  }, {
    title:"Anchor",
    src:require('../assets/anchor.png')
  },
  {
    title:"Onto",
    src:require('../assets/ont.png')
  },
  {
    title:"TokenPocket",
    src:require('../assets/tekan.png')
  },
];

function Web() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);

  const openModal = (image: Image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };

  return (
    <div className='bg-white h-auto text-blue-600 items-center inter space-y-5 px-4 py-10 flex flex-col'>
      <h1 className='text-2XL text-center'>Wallets</h1>
      <p className='px-10 line-clamp-6 text-center'>Multiple iOS and Android wallets support the protocol. <br /> Simply scan a QR code from your desktop computer screen to start securely using a dApp with your mobile wallet. <br /> Interaction between mobile apps and mobile browsers are supported via mobile deep linking.</p>
      <div className="grid grid-cols-2 md:grid-cols-4 items-center gap-4">
        {imageArray.map((image, index) => (
        <div className="flex items-center flex-col gap-y-2">
              <img
            key={index}
            src={image.src}
            alt={image.title}
            className="w-[200px]  cursor-pointer"
            onClick={() => openModal(image)}
          />
          <p className="text-sm text-black">{image.title}</p>
        </div>
        ))}
      </div>
      {modalOpen && selectedImage && (
        <Modal
          title={selectedImage.title}
          src={selectedImage.src}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

export default Web;
