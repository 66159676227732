import { ReactNode } from "react";

interface InputProps {
    id: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number;
    label: string;
    name: string;
    type: string;
    disabled?: boolean;
    icon?: React.ElementType;
}

const Input = ({ id, onChange, value, label, name, type, disabled, icon: Icon }: InputProps) => {
    return (
        <div className="relative">
            <div className="relative flex items-center"> {/* Wrapping input and icon in a flex container */}
                <input
                    id={id}
                    name={name}
                    value={value}
                    type={type}
                    onChange={onChange}
                    autoComplete="none"
                    disabled={disabled}
                    className="
                        block
                        rounded-md
                        px-6
                        pt-4
                        pb-1
                        w-[300px]
                        text-md
                        text-neutral-700
                        border
                        border-black-100
                        bg-white
                        appearance-none
                        focus:outline-none
                        focus:ring-0
                        peer
                        invalid:border-b-1
                        pl-10" // Adding padding for the icon
                    placeholder=" "
                />
                {Icon && (
                    <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                        <Icon color='#2A49F9' size='12' /> {/* Rendering the icon */}
                    </div>
                )}
            </div>
            <label
                htmlFor={id}
                className="
                    absolute 
                    text-xs
                    text-zinc-400
                    duration-150 
                    transform 
                    -translate-y-3 
                    scale-75 
                    top-4 
                    z-10 
                    origin-[0] 
                    left-6
                    peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 
                    peer-focus:scale-75
                    peer-focus:-translate-y-3"
            >
                {label}
            </label>
        </div>
    );
}

export default Input;
