

export const plan = [
    { label: "Select  a Plan", value: "" },
    { label: "Starter", value: "STARTER" },
    { label: "Silver", value: "SILVER" },
    { label: "Gold", value: "GOLD" },
   
];


export const coin =[
    {label:"Select a Coin to withdraw from", value:""},
    {label:"BTC", value:"BTC"},
    {label:"BITCOIN CASH", value:"BTCASH"},
    {label:"ETH", value:"ETH"},
    {label:"LTC", value:"LTC"},
]


export const payment = [
    { label: "Select A Payment method", value: "" },
    { label: "Visa/Mastercard", value: "visa_mastercard" },
    { label: "PayPal", value: "paypal" },
    { label: "Alipay", value: "alipay" },
    { label: "WeChat", value: "wechat" },
    { label: "Skrill", value: "skrill" }
];
