import { GrOverview } from "react-icons/gr";
import { FaChartBar } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { RiStockFill } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import { GrUpgrade } from "react-icons/gr";
import { GrLogout } from "react-icons/gr";
import { FaUser } from 'react-icons/fa';
import { TbTransferVertical } from "react-icons/tb";
import { BsCashStack } from "react-icons/bs";
// Define an enum to hold all the component types
export enum DashboardComponent {
  Overview = 'Overview',
  Crypto = 'Crypto',
  Forex = 'Forex',
  Transactions = 'Transactions',
  Upgrade = 'Upgrade',
  Transfer= "Transfer",
  Setting = 'Settings',
  Logout = 'Logout',
  Stock= "Stock",
  Withdraw= "Withdraw"
}

// Define the type for sidebar item
interface SidebarItem {
  icon: React.ElementType;
  title: string;
  component: DashboardComponent;
}

// Define sidebarArray
export const sidebarArray: SidebarItem[] = [
  { icon: GrOverview, title: "Overview", component: DashboardComponent.Overview },
  { icon: FaChartBar, title: "Crypto", component: DashboardComponent.Crypto },
  { icon: FaChartLine, title: "Forex", component: DashboardComponent.Forex },
  { icon: RiStockFill, title: "Stock", component: DashboardComponent.Stock },
  { icon: GrTransaction, title: "Transaction", component: DashboardComponent.Transactions },
  { icon: GrUpgrade, title: "Upgrade", component: DashboardComponent.Upgrade },
  { icon: TbTransferVertical, title: "Transfer", component: DashboardComponent.Transfer },
  { icon: BsCashStack, title: "Withdraw", component: DashboardComponent.Withdraw },
  { icon: FaUser, title: "Settings", component: DashboardComponent.Setting },
  { icon: GrLogout, title: "Logout", component: DashboardComponent.Logout }
];

export default sidebarArray;
