import { Link } from 'react-router-dom';
import Bri from '../../assets/BRI.png';
function Offer() {
  return (
    <div className="flex flex-col items-center justify-center space-y-4  poppins-regular">
      <div className="text-center text-3xl  md:text-6xl font-medium">About Us</div>
      <p className="md:text-4xl text-2xl mb-4 text-center font-medium">MOST FLEXIBLE CRYPTOCURRENCY INVESTMENT COMPANY</p>
      <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0">
        <div>
          <img src={Bri} alt="" />
        </div>
        <div className="text-justify w-full md:w-[1000px] mx-auto flex flex-col space-y-5 px-8">
          <h1 className='text-center text-3xl font-medium'>WE ARE BRINOXTRADING</h1>
          <p>
            BRINOXTRADING Investment Company is a distinctive investment company offering our investors access to
            high-growth investment opportunities in Bitcoin markets and other services. We implement best practices of
            trading & mining of Bitcoins through our operations, while offering flexibility in our investment plans. Our
            company benefits from an extensive network of global clients.
          </p>
          <p>
            As a leading stock exchange company, Brinoxtrading offers a profitable trading platform for Tesla
            stocks. In addition to Tesla stocks, we also offer stocks from various industries, including technology,
            healthcare, and finance. This allows our clients to diversify their portfolios and invest in a range of
            companies.
          </p>
          <h2 className='font-bold text-sm md:text-2xl'>OUR MISSION | OUR ADVANTAGES | OUR GUARANTEES</h2>
          <p>
            Our aim is to utilize our expertise & knowledge which will benefit our clients and the users of our services.
          </p>
          <p>
            Our mission as an official partner of Bitcoin Foundation is to help you enter and better understand the
            world of #1 cryptocurrency and avoid any issues you may encounter.
          </p>
          <p>
            We are here because we are passionate about open, transparent markets and aim to be a major driving force in
            widespread adoption, we are one the bests in cryptocurrency investment.
          </p>
          <Link to={'/about'} className='w-64  px-2 py-3 text-white bg-[#2a49f9]  text-center rounded-md font-medium'>Verify Certificate</Link>
        </div>
      </div>
    </div>
  );
}

export default Offer;
