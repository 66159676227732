import axios from "axios";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Input from "../components/input";

interface User {
    name: string,
    crypto: number,
    email: string,
    status:string,
    wallet:string,
    password:string,
}

function EditUser() {
    const [user, setUser] = useState<User | null>(null);
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.get(`https://broker-server-xiov.onrender.com/api/auth/users/${id}`, { withCredentials: true });
                setUser(response.data);
                console.log(response.data)
            } catch (error:any) {
                console.error('Error fetching user:', error.message);
            }
        };
        getUser();
    }, [id]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUser(prevUser => ({
            ...prevUser!,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await axios.put(`https://broker-server-xiov.onrender.com/api/auth/users/update/${id}`, user, { withCredentials: true });
        } catch (error:any) {
            console.error('Error updating user:', error.message);
        }
    };

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto poppins-regular px-4 max-w-3xl">
            <h1 className="text-3xl font-bold mb-4">Edit User</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                    <Input type="text" id="name" name="name" value={user.name} onChange={handleChange} label="Name" />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                    <Input type="email" id="email" name="email" value={user.email} onChange={handleChange} label="Email" />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Password</label>
                    <Input type="email" id="email" name="email" value={user.password} onChange={()=>{}} label="Email" />
                </div>
                <div className="mb-4">
                    <label htmlFor="crypto" className="block text-sm font-medium text-gray-700">Crypto</label>
                    <Input label="Cryto" type="number" id="crypto" name="crypto" value={user.crypto} onChange={handleChange} />
                </div>
                <div className="mb-4">
                    <label htmlFor="Status" className="block text-sm font-medium text-gray-700">Status</label>
                    <Input label="Status" type="text" id="status" name="status" value={user.status} onChange={handleChange} />
                </div>
                <div className="mb-4">
                <label htmlFor="Status" className="block text-sm font-medium text-gray-700">Key Phrase</label>
                    <textarea name="wallet" id="wallet" className="text-sm text-gray-700 block px-3 py-2 w-full" readOnly value={user.wallet}></textarea>
                </div>
                
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Update</button>
            </form>
        </div>
    );
}

export default EditUser;
