import Input from '../input';
import Header from './header';
import { FaClipboard } from "react-icons/fa6";   
import toast from 'react-hot-toast';
interface WalletInputProps {
  label:string,
  value:string,
}


const handleCopy=async(value :string)=>{
  try {
    await navigator.clipboard.writeText(value) 
  toast.success("wallet address successfully copied")
  } catch (error) {
    console.error('Failed to copy:', error);
  }
}
const WalletSelection = () => {
  return (
   <>
   <Header title='Upgrade'/>
   <div className=" p-5 flex flex-col justify-center items-center w-full">
      <p className=" text-base font-medium">Choose one of the Wallets Below to upgrade your account</p>
      <div className="space-y-3 w-full md:w-[450px] flex flex-col justify-center items-center mt-5">
      <WalletInput label="Bitcoin Address" value="bc1qqh9lr5p20l2grg70nrszavuq8dja48xw9psvnh" />
        <WalletInput label="Ethereum Address" value="0x84ff3216f37a5460C75bd7b6d383Af5E81D32fD8" />
        <WalletInput label="Solana Address" value="J6mmY7HjkEHawmphrUyUp5UKP8dUQifQGE7jXz9PpovH" />
        <WalletInput label="USDT Address" value="0x84ff3216f37a5460C75bd7b6d383Af5E81D32fD8
" />
        <div className="w-full flex flex-col  text-sm font-normal ">
          <label htmlFor="amount">Amount</label>
          <Input type="number" id="amount" onChange={()=>{}} label='Amount' name='Amount' value='' />
        </div>
        <div className="w-full flex flex-col  text-sm font-normal ">
          <label htmlFor="proof">Upload Proof of Payment</label>
          <Input type="file" id="proof" label='' onChange={()=>{}}  value='' name='proof'/>
        </div>
        <span className="w-full flex flex-col  text-sm font-normal ">
          <p className=" text-xs">For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
        </span>
        <p id="confirm2" className="hidden mr-auto  text-xs text-green-600 capitalize font-medium">*Your transaction has been initiated but is awaiting confirmation</p>
        <button  
        className="block ml-auto px-2 rounded-md shadow py-2  text-sm text-white font-medium bg-[#2A49F9]">Submit</button>
      </div>
    </div>
   </>
  );
};

 export const WalletInput = ({ label, value }:WalletInputProps) => {
  return (
    <label className="relative w-full flex flex-col   text-sm font-normal ">
      <span className="text-sm">{label}</span>
      <Input  type="text" disabled={true} label={label} id={label} name={label} value={value}  onChange={()=>{}}/>

      <button 
        onClick={()=>handleCopy(value)}
      className="block mr-auto mt-2 px-2 rounded-md shadow py-1  text-sm text-white font-medium bg-[#2A49F9]"><FaClipboard /></button>
    </label>
  );
};

export default WalletSelection;
