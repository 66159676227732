import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import './package.css';

function Packages() {
    const [minValueStarter, setMinValueStarter] = useState<number>(100); 
    const [minValueSilver, setMinValueSilver] = useState<number>(1000); 
    const [minValueGold, setMinValueGold] = useState<number>(20000); 

    const handleChangeStarter = (newValue: number) => {
        setMinValueStarter(newValue);
    };

    const handleChangeSilver = (newValue: number) => {
        setMinValueSilver(newValue);
    };

    const handleChangeGold = (newValue: number) => {
        setMinValueGold(newValue);
    };

    const calculateMaxValue = (minValue: number): number => {
        return minValue * 4; 
    };

    return (
        <div className='flex items-center justify-center px-6 py-4 flex-col poppins-regular'>
            <h1 className='md:text-4xl text-3xl text-center font-bold '>AFFORDABLE PACKAGES</h1>
            <h2 className='md:text-3xl text-2xl text-center  font-medium mb-4'>CHOOSE YOUR PREFERABLE PLAN FOR INVESTMENT.</h2>
            <div className="plans flex md:flex-row flex-col md:gap-x-6 gap-y-10 items-center justify-between " data-aos="zoom-out">
                <div className="w-[380px] h-auto border-primary border-2 p-3 shadow-md rounded-md flex space-y-5 items-center flex-col" >
                    {/* Starter Package */}
                    <div className='flex flex-col bg-primary w-full space-y-4 text-white itwms-center py-4'>
                        <h1 className='text-3xl font-medium text-center'>STARTER</h1>
                        <p className='text-2xl font-medium text-center'>WEEKLY 100% FOR 4 TIMES</p>
                    </div>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>WEEKLY RETURN</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>FOR 4 TIMES</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>100% ROI EACH TIME</p>
                    <div className="slider w-full">
                        {/* Slider for Starter Package */}
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Minimum:</span>
                                <span className='font-medium'> $100</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Maximum:</span>
                                <span className='font-medium'> $1000</span>
                            </p>
                        </div>
                        <p className="min text-center my-3 font-bold">${minValueStarter}</p>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            min={100}
                            max={1000}
                            value={minValueStarter}
                            onChange={handleChangeStarter}
                            trackClassName="example-track"
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        />
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>PER TIME:</span>
                                <span className='font-medium'>${minValueStarter}</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>TOTAL RETURN:</span>
                                <span className='font-medium'>${calculateMaxValue(minValueStarter)}</span>  
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-[380px] h-auto border-primary border-2 p-3 shadow-md rounded-md flex space-y-5 items-center flex-col" >
                    {/* Starter Package */}
                    <div className='flex flex-col bg-primary w-full space-y-4 text-white itwms-center py-4'>
                        <h1 className='text-3xl font-medium text-center'>SILVER</h1>
                        <p className='text-2xl font-medium text-center'>WEEKLY 100% FOR 4 TIMES</p>
                    </div>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>WEEKLY RETURN</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>FOR 4 TIMES</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>100% ROI EACH TIME</p>
                    <div className="slider w-full">
                        {/* Slider for Starter Package */}
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Minimum:</span>
                                <span className='font-medium'> $1000</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Maximum:</span>
                                <span className='font-medium'> $20000</span>
                            </p>
                        </div>
                        <p className="min text-center my-3 font-bold">${minValueSilver}</p>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            min={1000}
                            max={20000}
                            value={minValueSilver}
                            onChange={handleChangeSilver}
                            trackClassName="example-track"
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        />
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>PER TIME:</span>
                                <span className='font-medium'>${minValueSilver}</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>TOTAL RETURN:</span>
                                <span className='font-medium'>${calculateMaxValue(minValueSilver)}</span>  
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-[380px] h-auto border-primary border-2 p-3 shadow-md rounded-md flex space-y-5 items-center flex-col" >
                    {/* Starter Package */}
                    <div className='flex flex-col bg-primary w-full space-y-4 text-white itwms-center py-4'>
                        <h1 className='text-3xl font-medium text-center'>GOLD</h1>
                        <p className='text-2xl font-medium text-center'>WEEKLY 100% FOR 4 TIMES</p>
                    </div>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>WEEKLY RETURN</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>FOR 4 TIMES</p>
                    <p className='font-medium text-xl border-b-2 border-primary w-full text-center mb-2'>100% ROI EACH TIME</p>
                    <div className="slider w-full">
                        {/* Slider for Starter Package */}
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Minimum:</span>
                                <span className='font-medium'> $1000</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>Maximum:</span>
                                <span className='font-medium'> $20000</span>
                            </p>
                        </div>
                        <p className="min text-center my-3 font-bold">${minValueGold}</p>
                        <ReactSlider
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            min={20000}
                            max={80000}
                            value={minValueGold}
                            onChange={handleChangeGold}
                            trackClassName="example-track"
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        />
                        <div className="min-max flex items-center justify-between px-2">
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>PER TIME:</span>
                                <span className='font-medium'>${minValueGold}</span>
                            </p>
                            <p className="min flex flex-col gap-y-1 items-center">
                                <span className='font-medium'>TOTAL RETURN:</span>
                                <span className='font-medium'>${calculateMaxValue(minValueGold)}</span>  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Packages;
