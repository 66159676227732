import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";



interface User {
  _id: string;
  name: string;
  email: string;
  crypto: number;
  status: string;
}

function Admin() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/users", { withCredentials: true });
        setUsers(response.data);
        console.log(response.data)
      } catch (error:any) {
        console.error('Error fetching users:', error.message);
      }
    };
    getUsers();
  }, []);

  return (
    <div className="container mx-auto poppins-regular">
      <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-[#2a49f9]">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Name</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Email</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Status</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Edit</th>
          </tr>
        </thead>
        <tbody className=" divide-y divide-gray-200">
          {users.map(user => (
            <tr key={user._id}>
              <td className="px-6 py-4 whitespace-nowrap text-xs">{user.name}</td>
              <td className="px-6 py-4 whitespace-nowrap text-xs">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-xs">{user.status}</td>
              <td className="px-6 py-4 whitespace-nowrap text-xs">{user.crypto}</td>
              <td className="px-6 py-4 whitespace-nowrap text-xs">
                <Link to={`/admin/edit/${user._id}`}>
                  Edit <FaPencilAlt color="#2a49f9" className="inline mx-2" />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Admin;
