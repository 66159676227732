import Input from "../components/input";
import Button from "../components/button";
import Select from "../components/select";
import { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { plan } from "../data/selectdata";
import axios from "axios";
import toast from "react-hot-toast";
import { FaKey, FaPhone, FaUser} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import Navbar from "../components/navbar";

function SignUp() {
  const [formData, setFormData] = useState({
    email :"",
    password:"",
    name:"",
    phone:"",
    country:"",
    plan:"",
    confirmpassword:""
  })

const navigate= useNavigate()
  const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  const handleSubmit= async (e:React.FormEvent)=>{
    e.preventDefault()
    if(formData.password !== formData.confirmpassword){
      toast.error("Passwords don't match");
      return;
    }
  try {
    const response = await axios.post('https://broker-server-xiov.onrender.com/api/auth/register', formData, {
      withCredentials: true
    });
    
    if(response.status===201){
      toast.success('Successfully Registered')
     navigate('/profile')
    }
  } catch (error) {
    console.log(error)
  }
  
  }
  return (
  <>
  <Navbar/>
  <div className="w-full h-full my-20 flex justify-center text-white poppins-regular items-center  ">
      <div className="w-full md:w-[400px] h-auto shadow-2xl px-6  py-4  flex flex-col space-y-2 md:border-2   rounded-lg">
        <h2 className="text-center text-2xl font-bold">Get Started</h2>
        <h2 className="text-center text-sm font-medium">Please Fill in Your Details to get Started.</h2>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
        <label className="text-medium text-white " htmlFor="Email"> Full Name</label>
        <Input
        icon={FaUser}
            id="name"
            label="Full Name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
           
          />
           <label className="text-medium text-white " htmlFor="Email"> Your Email</label>
          <Input
          icon={MdMail}
            id="email"
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
           
          />
           <label className="text-medium text-white " htmlFor="Email"> Phone</label>
          <Input
          icon={FaPhone}
            id="phone"
            label="Phone"
            name="phone"
            type="number"
            value={formData.phone}
            onChange={handleChange}
           
          />
           <label className="text-medium text-white " htmlFor="Email"> Choose Your Plan</label>
      <Select
            options={plan}
            id="plan"
            onChange={(e) => setFormData({ ...formData, plan: e.target.value })}
            label="Choose a Plan"
            value={formData.plan}
          />
           <label className="text-medium text-white " htmlFor="Email"> Password</label>
          <Input
          icon={FaKey}
            id="password"
            label="Password"
            name="password"
            type="password"
            value={formData.password}
             onChange={handleChange}
          />
 <label className="text-medium text-white " htmlFor="Email"> Confirm Password</label>
<Input
            id="password"
            icon={FaKey}
            label="Confirm Password"
            name="confirmpassword"
            type="password"
            value={formData.confirmpassword}
             onChange={handleChange}
          />
          <Button size="lg" title="Register" type="primary" />
          <div className="flex justify-between items-center flex-col">
            <Link to="/login" className="text-blue-400 text-xs ">
              Already have an account? <span className="text-red-500 sevillana-regular text-md font-medium">Sign In</span>
            </Link>
          </div>
          <div className="term-copy  flex items-center flex-col">
          <Link to="/legal" className="text-primary text-xs">
              Terms and Conditions
            </Link>
            <p className="text-xs">
              &copy; Copyright 2024, Brinoxtrading All Rights Reserved
            </p>
          </div>
        </form>
      </div>
    </div>
  </>
  );
}

export default SignUp
