import React from "react"

interface CardSecProps {
    icon?:React.ElementType,
    title:string,
    desc:string,
    middleCard:boolean
}

export const CardSec = ({ icon: Icon, title, desc, middleCard }: CardSecProps) => {
    return (
        <div className={`p-6 rounded-[20px] poppins-regular shadow-md flex flex-col space-y-3}` }data-aos="fade-in">
            <div className="p-4 rounded-full bg-[#2a49f9] flex size-[50px] items-center justify-center">
                {Icon && <Icon color="white" size={24} />}
            </div>
            <h2 className="font-medium">{title}</h2>
            <p className="text-sm">{desc}</p>
        </div>
    );
};
