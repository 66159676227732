import React from "react";
import { FaBitcoin, FaEthereum, FaFacebook, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className='flex flex-col items-center justify-center md:flex-row w-full p-4  poppins-regular gap-x-6 '>
      <div className="links flex flex-col md:flex-row md:gap-x-6 md:w-2/3">
        <ul className="my-4 md:my-0">
          <h2 className="text-primary font-medium text-lg">Quick Links</h2>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>Home</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>About Us</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>FAQs</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>Terms & Conditions</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>Contact Us</li>
        </ul>
        <ul className="my-4 md:my-0">
          <h2 className="text-primary font-medium text-lg">Help & Support</h2>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>What is Bitcoin</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>How To Buy Bitcoin</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>Login</li>
          <li className='cursor-pointer hover:text-primary text-xs md:text-lg'>Forgot Password</li>
        </ul>
        <div className="my-4 md:my-0">
          <h1 className="text-primary font-medium text-lg">Contact us</h1>
          <h3>914 WILSHIRE BLVD #1000, LOS ANGELES, CA 90017, AMERICA</h3>
          <a href="mailto:SUPPORT@BRINOXTRADING.COM" className="text-primary font-medium">SUPPORT@BRINOXTRADING.COM</a>
          <h3>+1 (602) 738-7037</h3>
          <h3>MON-SAT 08AM ⇾ 05PM</h3>
          <div className="flex gap-x-2"><FaTwitter/><FaFacebook/></div>
        </div>
      </div>
      <div className="info flex flex-col items-center md:items-start md:w-1/3">
        <div className="flex flex-col pb-4 border-b-2 border-gray-400">
          <div>
            <h1 className="text-xl text-primary font-medium ">$198.76B</h1>
            <h2>MARKET CAP</h2>
            <h1 className="text-xl text-primary font-medium">69K+</h1>
            <h2>ACTIVE ACCOUNTS</h2>
          </div>
          <div>
            <h1 className="text-xl text-primary font-medium">234k</h1>
            <h2>WEEKLY TRANSACTIONS</h2>
            <h1 className="text-xl text-primary font-medium">127</h1>
            <h2>SUPPORTED COUNTRIES</h2>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <h1 className="text-lg text-primary font-medium">SUPPORTED PAYMENT</h1>
          <div className="flex gap-x-2">
            <FaBitcoin/>
            <FaEthereum/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
