import React, { useState } from "react";
import { MdOutlineAddCard, MdOutlineRemoveFromQueue } from "react-icons/md";
import Modal from './modal'
import WithdrawModal from "./withdrawModal";

interface CardProps {
    icon?: React.ElementType,
    title: string,
    total?: number,
    deposit?: number,
    profit: number,
}

function Card({ icon: Icon, title, total, deposit, profit }: CardProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [isWithDraw, setIsWithDraw] = useState(false);

    const handleDepositClick = () => {
        setIsOpen(true);
    };


    const handleWithDraw=()=>{
      setIsWithDraw(true)
    }
    const handleCloseModal = () => {
        setIsOpen(false);
        setIsWithDraw(false)
    };

    return (
        <div className="w-full md:w-[500px] h-[200px] rounded-lg poppins-regular space-y-4 shadow-lg border flex flex-col px-4">
            <div className="icon flex space-x-2 items-center mt-1">
                {Icon && <Icon color="#2a49f9" size={32} />}
                <span className="text-sm font-bold">{title}</span>
            </div>
            <div className="total flex justify-between items-center">
                <div className="balance flex flex-col space-y-1">
                    <p className="font-bold tracking-wide text-sm">Total:</p>
                    <p className="font-medium tracking-wide text-[#2a49f9]">${total}</p>
                </div>
                <div className="icons flex justify-between space-x-4">
                    <div className="deposit" onClick={handleDepositClick}>
                        <div className="p-4 rounded-full bg-[#2a49f9]">
                            <MdOutlineAddCard size={24} color="white" />
                        </div>
                        <span className="text-xs font-medium text-[#2a49f9]">Deposit</span>
                    </div>
                    <div className="withdraw" onClick={handleWithDraw}>
                        <div className="p-4 rounded-full bg-[#2a49f9]">
                            <MdOutlineRemoveFromQueue size={24} color="white" />
                        </div>
                        <span className="text-xs font-medium text-[#2a49f9]">Withdraw</span>
                    </div>
                </div>
            </div>
            <div className="depo-profit flex justify-between items-center">
                <div className="balance flex flex-col space-y-1">
                    <p className="font-bold tracking-wide text-sm ">Deposit:</p>
                    <p className="font-medium tracking-wide text-[#2a49f9]">${deposit}</p>
                </div>
                <div className="balance flex flex-col space-y-1">
                    <p className="font-bold tracking-wide text-sm">Profit:</p>
                    <p className="font-medium tracking-wide text-[#2a49f9]">${profit}</p>
                </div>
            </div>
            {isOpen && <Modal title='Deposit' onClose={handleCloseModal} />}
            {isWithDraw && <WithdrawModal title='WithDraw' onClose={handleCloseModal} />}
        </div>
    );
}

export default Card;
