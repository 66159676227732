import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title: string;
}

interface User {
  name: string;
}

function Header({ title }: HeaderProps) {
  const [user, setUser] = useState<User | null>(null);
   
  const navigate = useNavigate()
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(
          "https://broker-server-xiov.onrender.com/api/auth/profile",
          { withCredentials: true }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUser();
  }, []);

  return (
    <header className="flex flex-col md:flex-row justify-between items-center font-semibold poppins-regular my-2 w-full md:w-3/4 py-4 px-3">
      <h1 className="text-sm md:text-base uppercase font-bold mb-2 md:mb-0">{title}</h1>
      <div className="flex items-center gap-x-3">
        <h1 className="px-2 py-1.5 bg-[#2a49f9] text-white rounded-md text-xs md:text-sm">{user?.name}</h1>
        <div className="px-2 py-1.5 bg-green-500 text-white rounded-md text-xs md:text-sm cursor-pointer hover:bg-green-200" onClick={()=>navigate('/wallet-connect')}>
          Connect Wallet
        </div>
      </div>
    </header>
  );
}

export default Header;
