import { Swiper, SwiperSlide } from 'swiper/react';
import home3 from '../../assets/homenew.jpg';
import btc from '../../assets/btc.jpg'
import forex from '../../assets/forex.jpg'
import home2 from '../../assets/newhome.jpg';
import 'swiper/css/navigation';
import {  Autoplay } from 'swiper/modules';
import 'swiper/css';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <Swiper
      autoplay={true}
      navigation={false}
      modules={[Autoplay]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div style={{ backgroundImage: `url(${home2})` }} className="relative bg-cover opacity-95 bg-center h-[400px] md:h-[600px] lg:h-[800px] w-full">
          <div className="absolute inset-0 flex items-center justify-center flex-col text-white animate-slideIn">
            <h1 className="text-5xl md:text-8xl text-center font-bold" data-aos="fade-in">RELIABLE, SECURE, AND EASY <br /> WAY OF FOREX, CRYPTO ANS STOCK TRADING</h1>
            <Link to={'/signup'} className="capitalize bg-transparent font-medium rounded-md px-4 py-2 my-4 border-2 border-[#2a49f9]">Create a trading account</Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={{ backgroundImage: `url(${home3})` }} className="relative bg-cover opacity-95 bg-center h-[400px] md:h-[600px] lg:h-[800px] w-full">
          <div className="absolute inset-0 flex items-center justify-center flex-col text-white animate-slideIn">
            <h1 className="text-5xl md:text-7xl  text-center  font-bold " data-aos="fade-in">STOCK INVESTMENT THAT YOU CAN TRUST</h1>
            <Link to={'/signup'} className="capitalize bg-transparent font-medium rounded-md px-4 py-2 my-4  border-2 border-[#2a49f9]">Create a trading account</Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={{ backgroundImage: `url(${btc})` }}className="relative bg-cover opacity-95 bg-center h-[400px] md:h-[600px] lg:h-[800px] w-full">
          <div className="absolute inset-0 flex items-center justify-center flex-col text-white animate-slideIn">
            <h1 className="text-5xl md:text-7xl  text-center  font-bold " data-aos="fade-in">BITCOIN INVESTMENT THAT YOU CAN TRUST</h1>
            <Link to={'/signup'} className="capitalize bg-transparent font-medium rounded-md px-4 py-2 my-4  border-2 border-[#2a49f9]">Create a trading account</Link>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div style={{ backgroundImage: `url(${forex})` }} className="relative bg-cover opacity-95 bg-center h-[400px] md:h-[600px] lg:h-[800px] w-full">
          <div className="absolute inset-0 flex items-center justify-center flex-col text-white animate-slideIn">
            <h1 className="text-5xl md:text-7xl  text-center  font-bold " data-aos="fade-in">FOREX INVESTMENT THAT YOU CAN TRUST</h1>
            <Link to={'/signup'} className="capitalize bg-transparent font-medium rounded-md px-4 py-2 my-4  border-2 border-[#2a49f9]">Create a trading account</Link>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};
