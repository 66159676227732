import toast from 'react-hot-toast';
import Input from "../input";
import { useState } from 'react';
import alipay from '../../assets/alipay.png'
import weChatPay from '../../assets/wechatpay.webp'
interface ModalProps {
    title: string,
    onClose: () => void
}

const WithdrawModal = ({ title, onClose }: ModalProps) => {
    const [formData, setFormData] = useState({
        amount: "",
        type: "",
        address: ""
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = () => {
        if (!formData.amount || !formData.address) {
            toast.error("Please fill in all fields before submitting.");
            return;
        }

        toast.success("Withdrawal Successful, please contact customer care for more information");
        onClose()
    }

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="space-y-1 w-full md:w-[450px] flex flex-col justify-center items-center mt-5">
                                <h1 className="text-center font-medium text-lg md:text-2xl">{title} into your wallet</h1>
                                <p className="text-base font-medium">Choose one of the Wallets Below to upgrade your account</p>
                                <div className="w-full flex flex-col text-sm font-normal">
                                    <label htmlFor="amount">Amount to withdraw</label>
                                    <Input type="number" id="amount" onChange={handleChange} label='Amount' name='amount' value={formData.amount} />
                                </div>
                                <div className="w-full flex flex-col text-sm font-normal">
                                    <label htmlFor="address">Wallet Address:</label>
                                    <Input name="address" id="address" label="Wallet Address" onChange={handleChange} type="text" value={formData.address} />
                                    <div className="w-full flex flex-col text-sm font-normal">
                                        <label htmlFor="type">Please Indicate the cryptocurrency (Network)</label>
                                        <Input type="text" value={formData.type} label="E.g USDT (BEP20)" name="type" id="type" onChange={handleChange} />
                                    </div>
                                </div>
                                <button
                                    onClick={handleSubmit}
                                    className="block ml-auto px-2 rounded-md shadow py-2 text-sm text-white font-medium bg-[#2A49F9]">Submit</button>
                                   <div className="flex item-center">
                                   <img src={weChatPay} alt="" className='h-14 w-full cursor-pointer' onClick={()=>{toast.success("Please Contact the Support Team to withdraw with WeChatpay")}} />
                                    <img src={alipay} alt="" className='h-20 w-full cursor-pointer' onClick={()=>{toast.success("Please Contact the Support Team to withdraw with alipay")}} />
                                   </div>
                                <span className="w-full flex flex-col text-sm font-normal">
                                    <p className="text-xs">For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                                </span>
                                <p id="confirm2" className="hidden mr-auto text-xs text-green-600 capitalize font-medium">*Your transaction has been initiated but is awaiting confirmation</p>

                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button onClick={onClose} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawModal;
