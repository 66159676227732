import { useEffect, useState } from "react";
import Header from "./header";
import { LuAlertTriangle } from "react-icons/lu";
import Select from "../selectt";
import { coin, payment } from "../../data/selectdata";
import { TbTransfer } from "react-icons/tb";
import { CiCreditCard2 } from "react-icons/ci";
import Input from "../inputt";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


function Withdraw() {
  const [formData,setFormData]= useState({payment:"", coin:"", add:"", amount:"", percent:""})
const navigate = useNavigate()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "amount") {
      const amountValue = parseFloat(value);
      const percentValue = amountValue * 0.965; // Calculating 96.5% of the amount
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        percent: percentValue.toFixed(2), // Rounding to 2 decimal places
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };
 
  
  const handlePasteAddress = () => {
    navigator.clipboard.readText().then((pastedText) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        add: pastedText,
      }));
    });
  };


  const handleSubmit =()=>{
    if (!formData.coin || !formData.amount || !formData.add) {
      toast.error("please fill all details")
    }else{
      toast.success("Your Money is on the way")
      setFormData({payment:"", coin:"", add:"", amount:"", percent:""})
      navigate(0)
    }
  }
  return (
    <>
      <Header title="Transactions" />
      <div className="flex items-center text-centerx flex-col justify-center">
         <div className="flex item-center space-x-2 "><LuAlertTriangle className="h-4 w-4"  color="red"/> <span className="text-xs md:text-sm text-wrap">Funds will be sent at the current rate is USD {"(Market Price)"}, taking into account 3.5% of the exchange commision</span></div>
        <h1 className="text-lg font-bold md:text-4xl ">Withdraw  Crypto Directly into your Wallet</h1>
        <div className="flex flex-col space-y-4 my-5 shadow-md rounded-md px-4 py-6 ">
          <div className="flex items-center justify-center space-x-3">
          <TbTransfer color="blue" className="h-4 w-4"/>
          <Select options={coin} id="coin" 
           onChange={(e) =>
            setFormData({ ...formData, coin: e.target.value })
          }
         label="Choose a  Coin to withdraw from"
         value={formData.coin} />
          </div>
          <div className="flex items-center space-x-3">
          <div className="flex items-center space-x-3">
         <TbTransfer color="blue" className="h-4 w-4"/>
          <Input type="number" label="Amount to Withdraw"  value={formData.amount}  name="amount" id="amount" onChange={handleChange}/>
         </div>
         <div className="flex items-center space-x-3">
         <TbTransfer color="blue" className="h-4 w-4"/>
          <Input type="number" label="Amount Left After Commision"  value={formData.percent}  name="percent" id="percent" onChange={handleChange}/>
         </div>
          </div>
          <div className="flex items-center space-x-3 justify-center">
         
          <Input type="string"  label="Paste Wallet Address"  value={formData.add}  name="add" id="add" onChange={handleChange}/>
          <CiCreditCard2  color="blue" className="h-4 w-4" onClick={handlePasteAddress}/>
       </div>
          <button className="px-2 py-1.5 bg-primary text-white rounded-md text-xs md:text-sm cursor-pointer" onClick={handleSubmit}>
          Withdraw
        </button>
        </div>
      </div>
    </>
  );
}

export default Withdraw;
