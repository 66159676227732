import { useEffect, useState } from "react";
import Header from "./header";
import axios from "axios";

interface User {
  plan: string;
  crypto: number;
  status: string;
  updatedAt: string;
}

function Overview() {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/profile", { withCredentials: true });
        console.log(response.data);
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getUser();
  }, []);

  // Function to convert UTC timestamp to a readable format
  const formatDate = (timestamp: string): string => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // You can customize the date format as needed
  };

  return (
    <>
      <Header title="Transactions" />
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-1/4">
                Amount
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-1/4">
                Type
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-1/4">
                Plan
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-1/4">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sm:w-1/4">
                Date
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {user && (
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">{user.crypto}</td>
                <td className="px-6 py-4 whitespace-nowrap">Deposit</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.plan}</td>
                <td className="px-6 py-4 whitespace-nowrap">{user.status}</td>
                <td className="px-6 py-4 whitespace-nowrap">{formatDate(user.updatedAt)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Overview;
