import { TickerTape,MarketOverview,MiniChart } from "react-ts-tradingview-widgets";
import Navbar from '../components/navbar'
import Footer from '../components/footer'

function Market() {
  return (
    <>
    <Navbar/>
    <div className="market mt-20 ">
    <div className="flex flex-col justify-center w-full mx-auto">
   <MiniChart colorTheme="dark"  width="100%"></MiniChart>
   </div>
   <MarketOverview colorTheme="dark" height={400} width="100%" showFloatingTooltip></MarketOverview>
    </div>
    <Footer/>
    </>
  )
}

export default Market