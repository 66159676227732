import { useEffect, useState } from "react";
import Header from "./header";
import { FaChartBar } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa";
import { RiStockFill } from "react-icons/ri";
import { MarketOverview } from "react-ts-tradingview-widgets";
import axios from "axios";



interface User {
  crypto:number,
}


function Overview() {
  const [user, setUser] = useState<User | null>(null);

  const cardArray = [
    {
      icon: FaChartBar,
      title: "Cryptocurrency",
      balance: user?.crypto,
      depo: 0,
      profit: 0
    },
    {
      icon: FaChartLine,
      title: "Forex",
      balance: 0,
      depo: 0,
      profit: 0
    },
    {
      icon: RiStockFill,
      title: "Stocks",
      balance: 0,
      depo: 0,
      profit: 0
    }
  ];
  
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/profile", { withCredentials: true });
        setUser(response.data);
        console.log(user)
      } catch (error) {
        // Handle error
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  return (
    <>
      <Header title="Overview" />
      <div className="flex flex-col poppins-regular px-3 space-y-4">
        <div className="flex flex-col text-sm w-full md:w-[310px] font-medium text-black relative">
          Referral Code
          <input
            type="text"
            readOnly
            className="w-full h-[45px] mt-2 p-3 bg-[rgba(78,78,97,0.2)] backdrop-blur-[15px] focus:outline-none active:outline-none rounded-md"
            value="https://bit.ly/43grNQE"
          />
          <button className="px-3 h-[45px] bg-[#2A49F9] text-white absolute right-0 top-[28px] rounded-r-md">
            Copy
          </button>
        </div>
        <div className="flex flex-col md:flex-row justify-around py-3 space-y-6 md:space-y-0">
          {cardArray.map((item, index) => (
            <div key={index} className="w-full md:w-[210px] lg:w-[310px] h-[150px] rounded-md shadow-md border border-blue p-3 flex flex-col relative">
              <span className="flex flex-row items-center space-x-2">
                <div className="flex justify-center items-center w-[40px] h-[40px] bg-[#2A49F9] rounded-xl shadow-md">
                  <item.icon color="white" />
                </div>
                <p className="font-Anuphan text-sm text-[#2a49f9] font-medium">{item.title}</p>
              </span>
              <span className="flex flex-col mt-2">
                <p className="font-medium font-Anuphan text-black text-xs">Total Balance</p>
                <p className="font-medium font-Anuphan text-[#2a49f9] text-sm">${item.balance}</p>
              </span>
              <div className="absolute bottom-3 flex flex-row w-full justify-between pr-5">
                <span className="flex flex-col">
                  <p className="font-medium font-Anuphan text-black text-xs">Deposit</p>
                  <p className="font-medium font-Anuphan text-[#2a49f9] text-sm">${item.depo}</p>
                </span>
                <span className="flex flex-col">
                  <p className="font-medium font-Anuphan text-black text-xs">Profits</p>
                  <p className="font-medium font-Anuphan text-[#2a49f9] text-sm">${item.profit}</p>
                </span>
              </div>
            </div>
          ))}
        </div>
         <div>
          
         <MarketOverview colorTheme="light" height={400} width="100%" showFloatingTooltip></MarketOverview>
         </div>
      </div>
    </>
  );
}

export default Overview;