import metamask from '../../assets/metamask.png'
import coinbase from '../../assets/coinbase.png'
import trustwallet from '../../assets/trustwallet.png'
import phantom from '../../assets/phantom.webp'
function partners() {
  return (
    <div className="flex items-center flex-col justify-center h-full" >
        <h1 className='md:text-4xl font-medium max-w-2xl mx-auto text-3xl mb-4'data-aos='fade-in'>Our Partners and Sponsors</h1>
        <div className='grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-4  px-4' data-aos='fade-in'>
           <img src={metamask} alt=""  className='h-[100px] w-[200px] '/>
           <img src={coinbase} alt=""  className='h-[100px] w-[200px] '/>
           <img src={trustwallet} alt=""  className='h-[100px] w-[200px] ' />
           <img src={phantom} alt=""  className='h-[100px] w-[200px] ' />
        </div>
    </div>
  )
}

export default partners