import Navbar from '../components/navbar';
import Footer from '../components/footer';
import home from '../assets/home-2.png'

function About() {
  return (
    <>
      <Navbar />
      <div className="flex mt-20 mb-48 flex-col poppins-regular items-center  justify-center space-y-3 py-8">
      <div style={{ backgroundImage: `url(${home})` }} className="relative bg-cover bg-center flex items-center justify-center h-[300px] w-full">
     <h1 className="text-center font-bold leading-10 text-3xl px-12 md:text-6xl">About <span className='text-primary'>Brinoxtrading | Your Trusted Bitcoin Mining,<br /> Tesla Stocks And Investment Company</span></h1>
     </div>
        <div className="text-lg text-justify mb-6">
          <p>
          This is a mining platform or pool where you invest your money in Bitcoin and your Bitcoin investment undergoes a regular mining operation 24hrs/day making it possible for you to increase your financial capacity. 
          </p>
          <p>
          As a leading stock exchange company, Brinoxtrading offers a profitable trading platform for Tesla stocks. In addition to Tesla stocks, we also offer stocks from various industries, including technology, healthcare, and finance. This allows our clients to diversify their portfolios and invest in a range of companies. 
          </p>
          <p>
          This platform helps you to stay financially relevant, it gives you a financial freedom and also, it helps you to secure a financial future. <br />
The platform is stress-free and it gives you time to attend to your other needs.
          </p>
          <p>
          Our Job at Brinoxtrading LLC is to guide and show our clients how to invest and make money through this plartform without the need of a third party.
          </p>
          <p>
          Bitcoin mining operation is carried out 24hr/day generating constant steady interest on the invested bitcoin. An accumulated interest rate is distributed evenly accross every wallet registered and connected to the mining network.

          </p>
          <p>At Brinoxtrading we have the best group of mining expert and we mine using the best mining hardware( CGminer) which makes it possible for our clients to get the best mining services.</p>
          <p>
          Every wallet connected to the mining network gets a steady interest return which makes this investment platform more profiting and beneficial. <br />
Once your account is created and registered, your wallet will be credited with a start up bonus interest  of 0.0004btc from Top Leverage Ultimate
You can monitor and control your investment on Top Leverage Ultimate 24hrs a day. <br />
At Top Leverage Ultimate mining network we will never ask our clients for money, we always advise our clients to disregard any such person requesting money from them. 
          </p>
        </div>
        <div>
          <h1 className='text-3xl text-center font-bold'>
          WITHDRAWAL METHOD
          </h1>
          <p className='text-lg'>You can make a withdrawal after 7 days from your investment period.</p>
        </div>
        <h1 className="text-3xl  font-bold">Our Values</h1>
        <div className="text-lg text-justify mb-6">
          <p>
            At Brinoxtrading, we are guided by a set of core values that shape everything we do:
          </p>
          <ul className="list-disc list-inside">
            <p>Integrity: We operate with honesty, transparency, and ethical behavior.</p>
            <p>Innovation: We continuously seek new ways to improve and adapt to changing market conditions.</p>
            <p>Customer Focus: We prioritize the needs and satisfaction of our clients above all else.</p>
            <p>Security: We employ rigorous security measures to protect our customers' assets and data.</p>
            <p>Excellence: We strive for excellence in everything we do, setting high standards for ourselves and our services.</p>
          </ul>
        </div>
        <h1 className="text-3xl font-bold">Our Team</h1>
        <div className="text-lg text-justify mb-6">
          <p>
            Our team consists of dedicated professionals with diverse backgrounds and expertise in finance, technology, and customer service. Together, we work collaboratively to deliver innovative solutions and exceptional service to our clients.
          </p>
        </div>
        <h1 className="text-3xl font-bold">Contact Us</h1>
        <div className="text-lg text-justify">
          <p>
            If you have any questions or would like to learn more about Brinoxtrading, please don't hesitate to contact us. We're here to help!
          </p>
          <p>
            Email: info@Brinoxtrading.com<br />
            Phone: 1-800-123-4567
          </p>
        </div>
        <Footer />
      </div>

    </>
  );
}

export default About;
