import axios from "axios";
import { useEffect, useState } from "react";

interface User {
  name: string;
  email: string;
  _id: string;
  phone: number;
  plan: string;
}

function UserDetails() {
  const [user, setUser] = useState<User | null>(null);
  const userArray = [
    { label: "Name", value: user?.name },
    { label: "Email", value: user?.email },
    { label: "Phone", value: user?.phone },
    { label: "Plan", value: user?.plan },
    { label: "Upgrade Status", value: "Pending" },
    { label: "Withdrawal Status", value: "Pending" },
  ];

  useEffect(() => {
    const getUser = async () => {
      const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/profile", { withCredentials: true });
      setUser(response.data);
    };
    getUser();
  }, []);

  return (
    <div className="flex mx-4 flex-col">
      <p className="text-sm font-medium my-2">Your Details</p>
      <p className="text-sm font-medium">View your personal details here.</p>
      {/* Render user details */}
      {userArray.map((item, index) => (
           <div key={index} className="my-1 flex flex-col md:flex-row  py-2 border-b-2 ">
           <label className="text-sm font-medium md:w-1/4" htmlFor="">{item.label}</label>
           <input className="text-sm border rounded-md px-2 py-3 md:w-[450px] outline-none" type="text" value={item.value}  readOnly />
         </div>
      ))}
       <p className="text-sm font-medium my-2">Your Preferred Payment Method</p>
        <span className="text-xs">Save your Preferred payment method here.</span>
        <div  className="my-1 flex flex-col md:flex-row  py-2 ">
           <label className="text-sm font-medium md:w-1/4" htmlFor=""> Wallet Address:</label>
           <input className="text-sm border rounded-md px-2 py-3 md:w-[450px] outline-none" type="text" value={""}   />
         </div>
         <div  className="my-1 flex flex-col md:flex-row  py-2 ">
           <label className="text-sm font-medium md:w-1/4" htmlFor=""> Please Indicate  the  <br />cryptocurrency{"(Network)"}</label>
           <input className="text-sm border rounded-md px-2 py-3 md:w-[450px] outline-none" type="text" value={""} placeholder="E.g USDT (BEP20)"  />
         </div>
    </div>
  );
}

export default UserDetails;
