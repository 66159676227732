import Input from "../components/input";
import Button from "../components/button";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { MdMail } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import Navbar from "../components/navbar";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate= useNavigate()


  const handleSubmit=async(e:React.FormEvent)=>{
  e.preventDefault()
  try {
    const response = await axios.post("https://broker-server-xiov.onrender.com/api/auth/login",{email,password},{withCredentials:true})
    console.log(response)
    if(response.status== 200){
      toast.success('Welcome , Successfully Logged in')    
        navigate('/profile')
    }
  } catch (error) {
    toast.error("something went wrong")    
  }
   
  }

  return (
   <>
    <Navbar/>
    <div className="w-full h-screen flex justify-center poppins-regular items-center mx-auto ">
      <div className="w-full h-auto md:w-[400px] px-6 py-4 flex flex-col space-y-4 md:border-2 shadow-2xl rounded-lg">
        <h2 className="text-center text-2xl font-bold">Log in to your account</h2>
        <h2 className="text-center text-sm font-medium">Welcome Back! Please Enter Your Details.</h2>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
          <label className="text-medium text-white " htmlFor="Email"> Your Email</label>
          <Input
          icon={MdMail}
            id="email"
            label="Email"
            name="email"
            type="email"
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
          <label className="text-medium text-white " htmlFor="">Password</label>
          <Input
          icon={FaKey}
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
          <div className="flex items-center justify-center space-x-5">
          <p className="flex items-center text-xs gap-x-2 ">
          <input type="checkbox" className="flex justify-start"/>
          remember me ?
          </p>
          <span className="text-primary text-xs flex justify-end">Forgot your password?</span>
          </div>
          <Button size="lg" title="Login" type="primary" />
          <div className="flex justify-between items-center flex-col">
            <Link to="/signup" className="text-primary text-xs">
              Don't have an account? <span className="text-red-500 font-medium sevillana-regular text-md">Sign-Up</span>
            </Link>
          </div>
          <div className="term-copy  flex items-center flex-col">
          <Link to="/legal" className="text-primary text-xs">
              Terms and Conditions
            </Link>
            <p className="text-xs">
              &copy; Copyright 2024, Brinoxtrading All Rights Reserved
            </p>
          </div>
        </form>
      </div>
    </div>
   </>
  );
}

export default Login;
