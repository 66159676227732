import React, { useEffect } from 'react';
import AOS  from 'aos'
import 'aos/dist/aos.css'

interface TestimonialCardProps {
    address: string;
    desc: string;
    name: string;
}

export const TestimonialCard: React.FC<TestimonialCardProps> = ({ desc, address, name }) => (
    <div className='p-6 rounded-[20px] poppins-regular flex flex-col space-y-2 my-4 shadow-lg border-2'data-aos="fade-in">
        <p className="text-sm">{desc}</p>
        <h2 className="font-medium">{name},</h2>
        <h2 className="text-xs text-gray-600 ">{address}</h2>
    </div>
);

function Testimonial() {
    useEffect(()=>{
        AOS.init({duration:3000})
      },[])
    const testimonialArray = [
        {
            desc: "The customer service at this brokerage is exceptional. Any time I've had a question or concern, they've been quick to respond and provide helpful solutions",
            name: "David Clark",
            address: "Washington DC, USA"
        },
        {
            desc: "I've been consistently impressed by the professionalism and knowledge of the staff. They've helped guide me through complex financial decisions with ease.",
            name: "Sarah Johnson",
            address: "New York, USA"
        },
        {
            desc: "I've had a great experience working with this brokerage. Their attention to detail and commitment to client satisfaction sets them apart from others in the industry.",
            name: "Michael Smith",
            address: "Los Angeles, USA"
        },
        {
            desc: "Highly recommend this brokerage for anyone looking for reliable and efficient financial services. Their team goes above and beyond to ensure client needs are met.",
            name: "Emily Brown",
            address: "Chicago, USA"
        },
        {
            desc: "As a first-time investor, I was hesitant, but the team at this brokerage made the process smooth and understandable. I couldn't be happier with the results.",
            name: "John Davis",
            address: "Houston, USA"
        },
        {
            desc: "I've been with this brokerage for years and have always been impressed by their consistency and dedication to client success. Truly top-notch service.",
            name: "Amanda White",
            address: "San Francisco, USA"
        },
        {
            desc: "Exceptional knowledge and support from this brokerage. They take the time to understand your financial goals and tailor their services accordingly.",
            name: "Robert Johnson",
            address: "Miami, USA"
        }
    ];

    return (
      <>
      <h1 className="text-center font-bold my-4 text-2xl md:text-4xl">What Our Clients Say About Us</h1>
        <div className="md:grid md:grid-cols-4 gap-6 justify-center px-4 " >
            {testimonialArray.map((item, index) => (
                <TestimonialCard key={index} desc={item.desc} name={item.name} address={item.address} />
            ))}
        </div>
      </>
    );
}

export default Testimonial;
