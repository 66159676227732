import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Overview from '../components/dashboardcomponents/Overview';
import Transactions from '../components/dashboardcomponents/Transactions';
import Upgrade from '../components/dashboardcomponents/Upgrade';
import Setting from '../components/dashboardcomponents/Setting';
import Crypto from '../components/dashboardcomponents/Crypto';
import Forex from '../components/dashboardcomponents/Forex';
import Transfer from '../components/dashboardcomponents/Transfer';
import Withdraw from '../components/dashboardcomponents/WIthdraw';
import Stock from '../components/dashboardcomponents/Stock';
import { DashboardComponent, sidebarArray } from '../data/dashboard';
import logo from '../assets/logo.png';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';

const Dashboard: React.FC = () => {
  const [selectedComponent, setSelectedComponent] = useState<DashboardComponent | null>(DashboardComponent.Overview);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null); // Set user initially to null
  const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleItemClick = (component: DashboardComponent) => {
    setSelectedComponent(component);
  };

  useEffect(() => {
    // Simulate fetching user data (Replace this with your actual fetch call)
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          "https://broker-server-xiov.onrender.com/api/auth/profile",
          { withCredentials: true }
        );

        setUser(response.data)
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Simulated user data
        const userData = { username: 'JohnDoe' };
        setUser(userData);
        setIsLoading(false); // Set loading to false once user data is fetched
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case DashboardComponent.Overview:
        return <Overview />;
      case DashboardComponent.Crypto:
        return <Crypto />;
      case DashboardComponent.Forex:
        return <Forex />;
      case DashboardComponent.Transactions:
        return <Transactions />;
      case DashboardComponent.Upgrade:
        return <Upgrade />;
      case DashboardComponent.Setting:
        return <Setting />;
      case DashboardComponent.Stock:
        return <Stock />;
      case DashboardComponent.Transfer:
        return <Transfer />;
      case DashboardComponent.Withdraw:
        return <Withdraw />;
      default:
        return null;
    }
  };

  // Render loading spinner if user data is still loading
  if (isLoading) {
   return(
    <div className="flex  bg-white items-center poppins-regular flex-col space-y-4 justify-center h-screen">
      <MoonLoader color="#2a49f9" size={'100px'} />
      <h1 className='text-center text-[#2a49f9] text-2xl font-medium'>Loading User DashBoard</h1>
    </div>
   )
  }

  // Render Dashboard component once user data is fetched
  return (
    <div className="h-full w-full flex poppins-regular bg-white text-black flex-row">
      <div className={`sidebar w-20 md:w-[400px] border shadow-sm h-screen ${isSidebarOpen ? 'block' : 'hidden'}`}>
        <Link to="/" className="flex justify-center items-center">
          <img src={logo} alt="" className="h-12 w-auto" />
          <p className="text-2xl font-medium hidden md:block text-black logo">Brinoxtrading</p>
        </Link>
        {sidebarArray.map((item, index) => (
          <div key={index} className="flex items-center justify-center  w-full cursor-pointer" onClick={() => handleItemClick(item.component)}>
            <div className="flex items-center w-full bg-white hover:bg-[#f5f5f5] px-8 py-4 border-b-2">
              <div className="bg-[#2a49f9] rounded-full p-2">
                <item.icon color="white" size={20} />
              </div>
              <h2 className="ml-2 text-sm font-semibold hidden md:block">{item.title}</h2>
            </div>
          </div>
        ))}
      </div>
      <div className={`show w-full cursor-pointer`}>
        {/* Render the selected component */}
        {selectedComponent && renderSelectedComponent()}
      </div>
    </div>
  );
};

export default Dashboard;
