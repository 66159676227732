import { FaChartLine } from "react-icons/fa6";
import Card from './card';
import Header from './header';
import { ForexHeatMap, TechnicalAnalysis, MiniChart } from 'react-ts-tradingview-widgets';

function Overview() {
  return (
    <>
      <Header title="Forex" />
      <div className="flex flex-col md:flex-row md:p-6 p-3">
        <div className="flex flex-col space-y-5 md:w-1/2 md:pr-6">
        <div className='flex items-center justify-center'>
            <Card title="Forex" icon ={FaChartLine} total={0.00} deposit={0.00} profit={0.00} />
          </div>
          <div className="mt-4 shadow-md w-full">
            <ForexHeatMap colorTheme="light" width="100%" height={400} />
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:w-1/2">
          <TechnicalAnalysis colorTheme="light" width="100%" height={400} symbol="EURUSD" />
          <MiniChart colorTheme="light" symbol="JPYEUR" width="100%" />
        </div>
      </div>
    </>
  );
}

export default Overview;
