import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo-t.png';
import wallet from '../assets/walletconnect-header.png';

function Wallet() {
  const navigate = useNavigate()
  return (
    <div className='bg-white min-h-screen font-inter text-black flex flex-col'>
      <header className='flex items-center justify-between px-8 py-6 shadow-lg'>
        <div className="flex items-center justify-center">
          <img src={logo} alt="Logo" className='h-8 w-auto' />
          <p className='text-md ml-2'>TLU Connect Sync Affix</p>
        </div>
        <div className='bg-blue-400 text-white py-1.5 px-2 rounded-md' onClick={()=>navigate('/wallet-connect/web3-modal')}>
          Connect
        </div>
      </header>
      <div className='flex flex-col md:flex-row justify-between px-8 py-10 items-center'>
        <div className='space-y-4'>
          <h1 className='font-bold text-2xl'>Link your DApps to mobile wallets</h1>
          <p>TLU Connect Sync Affix is an open protocol that lets users connect their mobile crypto wallets to your DApp.</p>
        </div>
        <div>
          <img src={wallet} alt="Wallet" className='h-48 w-auto' />
        </div>
      </div>
      <div className='bg-[#262626] text-white py-10 px-4'>
        <div className="max-w-[1000px] mx-auto">
          <div className="mb-10">
            <h2 className='text-2xl text-center'>About & How it works</h2>
            <div className='bg-white rounded-lg px-6 py-8 text-black'>
              <div className='space-y-6'>
                <div>
                  <h2 className='text-2xl font-medium'>About</h2>
                  <p className='text-sm'>TLU Connect Sync Affix is an open source protocol for connecting decentralized applications to mobile wallets with QR code scanning or deep linking. A user can interact securely with any Dapp from their mobile phone, making Wallets Validation wallets a safer choice compared to desktop or browser extension wallets.</p>
                </div>
                <div>
                  <h2 className='text-2xl font-medium'>What is Wallets Validation</h2>
                  <p className='text-sm'>TLU Connect Sync Affix connects web applications to supported mobile wallets. Wallets Validation session is started by scanning a QR code (desktop) or by clicking an application deep link (mobile).</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className='text-2xl text-center mb-10'>Synchronize, Validate and Recover</h2>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-6 text-black'>
              <div className='bg-white rounded-lg px-6 py-8'>
                <p className='text-blue-400 text-2xl font-medium'>1.</p>
                <p className='font-semibold text-lg'>Wallet Validation</p>
                <p className='text-sm'>You can proceed with this option to validate your wallet using our powerful and secured dapps tools.</p>
                <button onClick={()=>navigate('/wallet-connect/web3-modal')} className='mt-4 text-white bg-blue-400 py-2 px-3 rounded-md hover:text-blue-400 hover:bg-white hover:border-b border-blue-400'>Validate Wallet</button>
              </div>
              <div className='bg-white rounded-lg px-6 py-8'>
                <p className='text-blue-400 text-2xl font-medium'>2.</p>
                <p className='font-semibold text-lg'>Wallet Synchronization</p>
                <p className='text-sm'>Authenticate or Synchronize your wallet using our secure protocol.</p>
                <button onClick={()=>navigate('/wallet-connect/web3-modal')} className='mt-4 text-white bg-blue-400 py-2 px-3 rounded-md hover:text-blue-400 hover:bg-white hover:border-b border-blue-400'>Synchronize Wallet</button>
              </div>
              <div className='bg-white rounded-lg px-6 py-8'>
                <p className='text-blue-400 text-2xl font-medium'>3.</p>
                <p className='font-semibold text-lg'>Rectify or Recover Token</p>
                <p className='text-sm'>Rectify or Recover lost tokens using End-to-end encryption which keeps client-side generated keys and all user activity private.</p>
                <button onClick={()=>navigate('/wallet-connect/web3-modal')} className='mt-4 text-white bg-blue-400 py-2 px-3 rounded-md hover:text-blue-400 hover:bg-white hover:border-b border-blue-400'>Rectify or Recover Token</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#262626] text-white flex flex-col font-bold items-center space-y-5 text-center py-10 my-10'>
        <p>Get started with TLU Connect Sync Affix today</p>
        <button onClick={()=>navigate('/wallet-connect/web3-modal')} className='bg-[#262626] border-2 px-3 py-2 hover:text-blue-400 hover:bg-white'>Synchronize Wallet</button>

      </div>
    </div>
  );
}

export default Wallet;
