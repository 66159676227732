import { FaUser } from 'react-icons/fa';
import { SiBitcoincash } from 'react-icons/si';
import { IoRocket } from 'react-icons/io5';
import {FaWallet} from 'react-icons/fa'

const investArray = [
  {
    icon: FaWallet,
    step: 'Download Bitcoin Wallet',
    note: 'Get it on PC or Mobile to create, send and receive bitcoins.',
  },
  {
    icon: SiBitcoincash,
    step: 'Add Funds & Start Investment',
    note: 'Add bitcoins you’ve created or exchanged via credit card.',
  },
  {
    icon: IoRocket,
    step: 'Withdraw Your Profit',
    note: 'Request for withdrawal and receive it within 1day.',
  }
];

function Invest() {
  return (
    <div className="flex flex-col my-4 items-center mx-3 poppins-regular">
      <h1 className="text-2xl md:text-5xl mb-4 font-bold">We Make Investing Easy</h1>
      <h2 className='font-medium text-xl md:text-3xl text-center capitalize mb-3'>Get started in 3 simple steps</h2>
      <div className="flex flex-col md:flex-row md:space-x-6 items-center md:justify-between space-y-4 md:border-2 w-full  shadow-md px-4">
        {investArray.map((item, index) => (
          <div key={index} className="px-4 py-4 h-[200px] my-3 rounded-md w-auto   flex flex-col md:flex-row space-x-3 items-center justify-center">
            <item.icon size={'40'} color='blue' />
            <div>
            <p className="text-sm md:text-xl font-bold">{item.step}</p>
            <p className="text-[#777B83] md:w-[300px] font-normal tracking">{item.note}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Invest;
