import { useEffect, useState } from "react";
import Header from "./header";
import { LuAlertTriangle } from "react-icons/lu";
import Select from "../selectt";
import { coin, payment } from "../../data/selectdata";
import { TbTransfer } from "react-icons/tb";
import { CiCreditCard2 } from "react-icons/ci";
import Input from "../inputt";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Overview() {
  const [formData, setFormData] = useState({ payment: "", coin: "", card: "" });
  const navigate = useNavigate();

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (!formData.coin || !formData.payment || !formData.card) {
      toast.error("Please fill in all details.");
    } else {
      toast.success("Your money is on the way.");
      setFormData({ payment: "", coin: "", card: "" });
      navigate(0);
    }
  };

  return (
    <>
      <Header title="Transactions" />
      <div className="flex items-center text-centerx flex-col justify-center">
        <div className="flex item-center space-x-2 ">
          <LuAlertTriangle className="h-4 w-4" color="red" />
          <span className="text-xs md:text-sm text-wrap">
            Funds will be sent at the current rate is USD (Market Price),
            taking into account 3.5% of the exchange commission
          </span>
        </div>
        <h1 className="text-lg font-bold md:text-4xl ">
          Transfer Crypto Directly into your Bank Account
        </h1>
        <div className="flex flex-col space-y-4 my-5 shadow-md rounded-md px-4 py-6 ">
          <div className="flex items-center space-x-3">
            <TbTransfer color="blue" className="h-4 w-4" />
            <Select
              options={coin}
              id="coin"
              onChange={(e) =>
                setFormData({ ...formData, coin: e.target.value })
              }
              label="Choose a Coin to withdraw from"
              value={formData.coin}
            />
          </div>
          <div className="flex items-center space-x-3">
            <TbTransfer color="blue" className="h-4 w-4" />
            <Select
              options={payment}
              id="payment"
              onChange={(e) =>
                setFormData({ ...formData, payment: e.target.value })
              }
              label="Choose a Payment Method"
              value={formData.payment}
            />
          </div>
          <div className="flex items-center space-x-3">
            <CiCreditCard2 color="blue" className="h-4 w-4" />
            <Input
              type="text"
              label="Enter Card Details"
              value={formData.card}
              name="card"
              id="card"
              onChange={handleChange}
            />
          </div>
          <button className="px-2 py-1.5 bg-primary text-white rounded-md text-xs md:text-sm cursor-pointer" onClick={handleSubmit}>
            Withdraw
          </button>
        </div>
      </div>
    </>
  );
}

export default Overview;
