import axios from "axios";
import Header from "./header";
import UserDetail from "./user";
import Verify from "./verify";
import { useEffect, useState } from "react";



type SettingPageButtonProps = "userdetails" | "verification";

function Overview() {
  const [comp, setComp] = useState<SettingPageButtonProps>("userdetails");
  const handleComponentChange = (component: SettingPageButtonProps) => {
    setComp(component);
  };

  return (
    <>
      <Header title="Settings" />
      <div className="mt-4 flex ml-4 gap-x-4">
      <p className={comp === "userdetails" ? "text-sm border-b-2 border-blue-500" : "text-sm cursor-pointer"} onClick={() => handleComponentChange("userdetails")}>
          My Details
        </p>
        <p className={comp === "verification" ? "text-sm border-b-2 border-blue-500" : "text-sm cursor-pointer"} onClick={() => handleComponentChange("verification")}>
          Verification
        </p>
      </div>
      {/* Render component based on state */}
      {comp === "userdetails"  && <UserDetail   />}
      {comp === "verification" && <Verify />}
    </>
  );
}

export default Overview;
