import { CardSec } from "../dashboardcomponents/cardsec";
import { LuUsers } from "react-icons/lu";
import { HiOutlineLockClosed } from "react-icons/hi";
import { GrRobot } from "react-icons/gr";
import { IoStatsChartSharp } from "react-icons/io5";
import { AiOutlineCustomerService } from "react-icons/ai";

const serviceArray = [
  {
    title: "Personal account manager",
    icon: LuUsers,
    desc: "Our account managers are dedicated to providing individualized support and guidance to clients, helping them manage their investment portfolios and navigate the financial markets.",
  },
  {
    title: "Free access to forex market research",
    icon: HiOutlineLockClosed,
    desc: "Gain valuable insights and stay ahead of the curve with our free access to forex market research.",
  },
  {
    title: "Daily access to forex trading signal",
    icon: IoStatsChartSharp,
    desc: "Get access to daily signals to trade forex, stocks, and crypto.",
  },
  {
    title: "Trading Bots",
    icon: GrRobot,
    desc: "With real-time trading, traders can make quick decisions based on current market conditions and execute trades immediately.",
  },
  {
    title: "24/7 Customer Care",
    icon: AiOutlineCustomerService,
    desc: "Our customer care team is available 24/7 to assist you with any questions or issues you may have. Whether it's in the middle of the night or on a holiday, you can rely on us to be there for you.",
  },
];

function service() {
  return (
    <div className="md:grid md:grid-cols-3  flex flex-col items-center gap-6 justify-center" >
      {serviceArray.map((service, index) => (
        <CardSec
          key={index}
          title={service.title}
          icon={service.icon}
          desc={service.desc}
          middleCard={index === 2} // Condition to identify the middle card
          
        />
      ))}
    </div>
  );
}

export default service;
