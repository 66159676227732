import { FaChartBar } from "react-icons/fa";
import Card from './card';
import Header from './header';
import { StockMarket, TechnicalAnalysis, MiniChart } from 'react-ts-tradingview-widgets';

function Overview() {
  return (
    <>
      <Header title="Stock" />
      <div className="flex flex-col md:flex-row md:p-6 p-0">
        <div className="flex flex-col space-y-5 md:w-1/2 md:pr-6">
          <div className='flex items-center justify-center'>
            <Card title="Stock"  icon={FaChartBar} total={0.00} deposit={0.00} profit={0.00} />
          </div>
          <div className="mt-4 shadow-md w-full">
            <StockMarket colorTheme="light" height={400} width="100%" />
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:w-1/2">
          <TechnicalAnalysis colorTheme="light" width="100%" height={400} symbol="SGBX" />
          <MiniChart colorTheme="light" symbol="TSLA" width="100%" />
        </div>
      </div>
    </>
  );
}

export default Overview;
