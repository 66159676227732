import { Route,Routes,Navigate} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import Market from "./pages/Market";
import Contact from "./pages/Contact";
import Admin from './pages/Admin'
import EditUser from "./pages/EditUser";
import Legal from "./pages/Legal";
import About from "./pages/About";
import Wallet from "./pages/Wallet";
import  {Toaster}  from "react-hot-toast"; 
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from "react";
import Web from "./pages/Web";

function App() {
  useEffect(()=>{
    AOS.init({duration:3000})
  },[])
  return (
 
 <>
    <Routes>
    <Route path="/" index element={<Home/>}/>
    <Route path="/login"  element={<Login/>}/>
    <Route path="/signup" element={<SignUp/>}/>
    <Route path="/profile" element={  <Dashboard/> }/>
    <Route path="/market" element={<Market/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/wallet-connect" element={<Wallet/>}/>
    <Route path="/wallet-connect/web3-modal" element={<Web/>}/>
    <Route path="/legal" element={<Legal/>}/>
    <Route path="/checkout/admin/dashboard" element={<Admin/>}/>
    <Route path="/about" element={<About/>}/>
    <Route path="/admin/edit/:id" element={<EditUser/>}/>
   </Routes>
   <Toaster/>
</>
  );
}

export default App;
