import { FaBitcoin } from "react-icons/fa";
import Card from './card';
import Header from './header';
import { CryptoCurrencyMarket, TechnicalAnalysis, MiniChart } from 'react-ts-tradingview-widgets';
import { useEffect, useState } from "react";
import axios from "axios";
interface User {
  crypto:number,
}


function Overview() {
  
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/profile", { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        // Handle error
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  return (
    <>
      <Header title="Cryptocurrency" />
      <div className="flex flex-col md:flex-row md:p-6 p-3">
        <div className="flex flex-col space-y-5 md:w-1/2 md:pr-6">
        <div className='flex items-center justify-center'>
            <Card title="Cryptocurrency" icon={FaBitcoin} total={user?.crypto} deposit={user?.crypto} profit={0.00} />
          </div>
          <div className="mt-4 shadow-md w-full md:w-[800px]">
            <CryptoCurrencyMarket colorTheme="light" width="100%" height={400} />
          </div>
        </div>
        <div className="flex flex-col space-y-5 md:w-1/2">
          <TechnicalAnalysis colorTheme="light" width="100%" height={400} symbol="BITCOINUSDT" />
          <MiniChart colorTheme="light" symbol="ETHUSDT" width="100%" />
        </div>
      </div>
    </>
  );
}

export default Overview;
