import { IoIosMenu } from "react-icons/io";
import { Link } from "react-router-dom";
import Logo from '../assets/logo.png'
import { useState, useEffect } from "react";
import axios from "axios";

interface User {
  name: string;
  email: string;
  _id: string;
  phone: number;
  plan: string;
}

const Navbar = () => {
  const [user, setUser] = useState<User | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navbarItems = [
    { title: "Home", link: "/" },
    { title: "Market", link: "/market" },
    { title: "Legal", link: "/legal" },
    { title: "About Us", link: "/about" },
    { title: "Contact Us", link: "/contact" },
  ];

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get("https://broker-server-xiov.onrender.com/api/auth/profile", { withCredentials: true });
        setUser(response.data);
      } catch (error) {
        // Handle error
        console.error("Error fetching user:", error);
      }
    };
    getUser();
  }, []);

  return (
    <nav className="flex justify-between items-center px-5 md:px-12 py-3 md:py-5 fixed top-0 left-0 w-full z-50  shadow-md">
      <div className="flex items-center">
        <Link to="/" className="text-lg md:text-2xl font-medium flex items-center justify-center space-x-2 logo">
          <img src={Logo} alt="" className="h-12 w-auto"/>
          BRINOXTRADING
        </Link>
        <div className="hidden md:flex ml-8 space-x-6 font-Anuphan text-sm font-medium capitalize">
          {navbarItems.map((item, index) => (
            <Link key={index} to={item.link} className="hover:text-blue-500">
              {item.title}
            </Link>
          ))}
        </div>
      </div>
      {user ? (
        <div className="hidden md:flex space-x-5 lg:space-x-8 font-Anuphan text-sm font-medium capitalize items-center">
          <Link to="/profile" className="bg-[#2A49F9] text-white rounded-md px-3 py-2">Profile</Link>
        </div>
      ) : (
        <div className="hidden md:flex space-x-5 lg:space-x-8 font-Anuphan text-sm font-medium capitalize items-center">
          <Link to="/login" className="hover:text-blue-500">Log in</Link>
          <Link to="/Signup">
            <button className="px-3 py-2 bg-[#2A49F9] text-white rounded-md hover:bg-blue-700">
              Get Started
            </button>
          </Link>
        </div>
      )}
      <IoIosMenu
        size={24}
        className="block md:hidden text-white"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      />
      {mobileMenuOpen && (
        <div className="fixed top-16 left-0 w-full bg-black text-white shadow-md py-4 px-6 font-Anuphan text-sm text-center">
          {navbarItems.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              onClick={() => setMobileMenuOpen(false)}
              className="block py-2 hover:text-blue-500"
            >
              {item.title}
            </Link>
          ))}
          {user ? (
            <Link to="/profile" onClick={() => setMobileMenuOpen(false)} className="block py-2 hover:text-blue-500">Profile</Link>
          ) : (
            <>
              <Link to="/login" onClick={() => setMobileMenuOpen(false)} className="block py-2 hover:text-blue-500">Log in</Link>
              <Link to="/Signup" onClick={() => setMobileMenuOpen(false)}>
                <button className="block w-full mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700">
                  Get Started
                </button>
              </Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
