import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { TickerTape, MarketOverview, MiniChart } from "react-ts-tradingview-widgets";
import Packages from "../components/homepage/packages";
import Invest from "../components/homepage/invest";
import Offer from "../components/homepage/offer";
import Service from "../components/homepage/service";
import Testimonial from "../components/homepage/testimonial";
import Swiper from '../components/homepage/slider';
import { AdvancedRealTimeChart,Timeline } from "react-ts-tradingview-widgets";
import { FundamentalData } from "react-ts-tradingview-widgets";
import { FaWhatsapp } from "react-icons/fa";
import Partner from "../components/homepage/partners"
function Home() {
  return (
    <div className=" overflow-x-hidden poppins-regular">
      <Navbar />
      <div className="h-full">
        <div className="mt-20 mx-auto">
          <TickerTape colorTheme="dark"></TickerTape>
        </div>
        <Swiper />
        <div className="my-6">
          <Invest />
        </div>
        <div className="my-6">
          <Offer />
        </div>
        <div className="my-6">
          <Service />
        </div>
        <div className="my-6">
          <Packages />
        </div>
        

<FundamentalData colorTheme="dark" symbol="BTCUSDT" height={400} width="100%"></FundamentalData>
        <div className="my-6">
          <Testimonial />
        </div>
        <div className="my-6">
          <AdvancedRealTimeChart theme="dark" symbol="BTCUSDT" width={'full'}></AdvancedRealTimeChart>
        </div>
        <div className="my-6">
          <Partner/>
        </div>
        <div className="my-6">
        <Timeline colorTheme="dark" feedMode="market" market="crypto" height={400} width="100%"></Timeline>
        </div>
      </div> 
      <Footer />
      <a href="https://wa.link/p4zsd6" className="fixed bottom-0 left-0 mx-4 mb-4">
       <div className="bg-white rounded-md px-2 py-3 flex items-center space-x-3 ">
       <FaWhatsapp color="green" size={40}/>
       <p className="text-black text-sm hidden md:block ">Connect with us on Whatapp?</p>
       </div>
      </a >
    </div>
  );
}

export default Home;
