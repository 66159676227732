import { FaClipboard } from "react-icons/fa6";   
import toast from 'react-hot-toast';
import { WalletInput } from "./Upgrade";
import Input from "../input";

interface ModalProps {
    title: string,
    onClose: () => void
}

const Modal = ({ title, onClose }: ModalProps) => {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="space-y-1 w-full md:w-[450px] flex flex-col justify-center items-center mt-5">
                                <h1 className="text-center font-medium text-lg md:text-2xl">{title} into your wallet</h1>
                                <p className="text-base font-medium">Choose one of the Wallets Below to upgrade your account</p>
                                <WalletInput label="Bitcoin Address" value="bc1qck5keg54eyr6fam7h9aqy4khetssrdmqrkm0fx" />
        <WalletInput label="Ethereum Address" value="0x1f0Ad44BD7aaee61dB3f8Fd51b761abA0746746E" />
        <WalletInput label="Doge Address" value="DDunBgTsy83PMP2woxGvb2RuSK9cSXA54V" />
        <WalletInput label="USDT TRC" value="0x1f0Ad44BD7aaee61dB3f8Fd51b761abA0746746E" />
                                <div className="w-full flex flex-col text-sm font-normal">
                                    <label htmlFor="amount">Amount</label>
                                    <Input type="number" id="amount" onChange={() => { }} label='Amount' name='Amount' value='' />
                                </div>
                                <div className="w-full flex flex-col text-sm font-normal">
                                    <label htmlFor="proof">Upload Proof of Payment</label>
                                    <Input type="file" id="proof" label='' onChange={() => { }} value='' name='proof' />
                                </div>
                                <span className="w-full flex flex-col text-sm font-normal">
                                    <p className="text-xs">For your convenience, we offer bank transfer as a payment option. To obtain further details, please reach out to our live customer support.</p>
                                </span>
                                <p id="confirm2" className="hidden mr-auto text-xs text-green-600 capitalize font-medium">*Your transaction has been initiated but is awaiting confirmation</p>
                                <button
                                    className="block ml-auto px-2 rounded-md shadow py-2 text-sm text-white font-medium bg-[#2A49F9]">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button onClick={onClose} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
