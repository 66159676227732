import React, { useState } from "react";
import Footer from "../components/footer";
import Input from "../components/input";
import Navbar from "../components/navbar";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    // Here you can handle form submission, e.g., send data to server or display a success message
    console.log(formData);
    // Reset form after submission
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <>
    <Navbar/>
      <div className="flex mt-20 flex-col items-center justify-center px-4 md:px-6 lg:px-12 py-8">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center">
          Have a Question? Talk To Us
        </h1>
        <form className="w-full max-w-md" onSubmit={handleSubmit}>
          <div className="mb-4">
            <Input
              label="Your Name"
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <Input
              label="Email"
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <textarea
              className="resize-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows={5}
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Send us a Message
            </button>
          </div>
        </form>
      </div>
      <div className="fixed bottom-0 w-full">
     <Footer />
     </div>
    </>
  );
}

export default Contact;
