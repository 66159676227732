import React from "react";

interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  label: string;
  options: Option[];
}

const Select: React.FC<SelectProps> = ({ id, onChange, value, label, options }) => {
  return (
    <div className="relative">
      <select
        id={id}
        value={value}
        onChange={onChange}
        className="
          block
          rounded-md
          px-6
          pt-4
          pb-1
          w-full
          text-sm
          text-neutral-700
          border
          border-black-100
          bg-white
          appearance-none
          focus:outline-none
          focus:ring-0
          peer
          invalid:border-b-1
        "
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label
        htmlFor={id}
        className="
          absolute
          text-xs
          text-zinc-400
          duration-150
          transform
          -translate-y-3
          scale-75
          top-4
          z-10
          origin-[0]
          left-6
          peer-placeholder-shown:scale-100
          peer-placeholder-shown:translate-y-0
          peer-focus:scale-75
          peer-focus:-translate-y-3
        "
      >
        {label}
      </label>
    </div>
  );
};

export default Select
